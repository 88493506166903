<template>
<div class="d-grid gap-2">
    <button class="btn btn-outline-danger mb-1" 
            @click="onClick"
             :disabled="disabled"><i class="fa-solid fa-trash-can"></i> Delete</button>
</div>
</template>

<script>
import useTableButton from '../composables/useTableButton';
export default {
    setup(props) {
        const {
            disabled,

            onClick
        } = useTableButton(props)
        
        return {
            disabled,

            onClick
        }
    }
}
</script>